<template>
  <v-row>
    <v-col cols="12" :class="status ? 'px-3 px-lg-3' : null">
      <div v-if="status && statusCustomer && !maintenanceMode" id="order-list">
        <!-- app drawer -->

        <v-snackbars
          :objects.sync="snackbar"
          width="500"
          :timeout="1500"
          bottom
          right
          color="error"
          transition="slide-y-transition"
        >
          <template v-slot:default="{ message }">
            <div v-if="message.status">
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                Ürününden <strong> {{ message.count }} {{ message.birim }}</strong>
                <v-chip :color="message.color" label class="ml-1" small> {{ message.plant }} Fabrikasından </v-chip>
                sepetinize eklendi
              </div>
            </div>
            <div v-else>
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                <strong> Sepetten çıkartıldı</strong>
              </div>
            </div>
          </template>
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-tl-lg'"
          class="overflow-hidden"
        >
          <v-sheet
            v-if="$vuetify.breakpoint.xsOnly"
            :class="userData.role === 'PLSYR' ? 'search-mobil-plasiyer' : 'search-mobil'"
            color="transparent"
          >
            <v-text-field
              v-model="search"
              background-color="nightDark"
              solo
              :prepend-inner-icon="icons.mdiMagnify"
              label=""
              clearable
              hide-details
              class="rounded-0"
            >
              <template v-slot:label>
                <div class="text-caption">Aradığınız ürünü yazın</div>
              </template>
            </v-text-field>
          </v-sheet>

          <!-- table -->
          <v-sheet :style="$vuetify.breakpoint.xsOnly ? 'padding-top:50px' : null">
            <v-data-table
              v-model="selectedRows"
              :headers="tableColumns"
              :items="dataListTable"
              :search="search"
              :items-per-page="-1"
              fixed-header
              hide-default-footer
              :height="dataTableHeight"
              :loading="loading"
              :item-class="itemRowBackground"
            >
              <template v-if="userData.role === 'PLSYR'" v-slot:header.pictures="{ header }">
                <v-btn color="secondary" fab small elevation="0" @click="refreshCustomer">
                  <v-icon>
                    {{ icons.mdiArrowLeft }}
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:header.product_code="{ header }">
                <div>
                  <v-text-field
                    v-model="product_code"
                    hide-details
                    filled
                    background-color="grey"
                    class="rounded-0"
                    :prepend-icon="icons.mdiMagnify"
                    label="Kod"
                  ></v-text-field>
                </div>
              </template>

              <template v-slot:header.product_title="{ header }">
                <div>
                  <v-text-field
                    v-model="product_title"
                    hide-details
                    filled
                    background-color="grey"
                    :prepend-icon="icons.mdiMagnify"
                    label="Ürün Adı"
                  ></v-text-field>
                </div>
              </template>

              <template v-slot:header.tekirdag_fabrikasi="{ header }">
                <div class="my-2">
                  <div class="mb-2">
                    <v-chip
                      color="secondary"
                      label
                      outlined
                      v-text="$vuetify.breakpoint.mdAndUp ? 'Tekirdağ Fabrikası' : 'Tekirdağ'"
                    />
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div style="width: 100px" class="text-right">Fiyat</div>
                    <div class="text-right">Miktar</div>
                  </div>
                </div>
              </template>

              <template v-slot:header.konya_fabrikasi="{ header }">
                <div class="my-2">
                  <div class="mb-2">
                    <v-chip
                      color="warning"
                      label
                      outlined
                      v-text="$vuetify.breakpoint.mdAndUp ? 'Konya Fabrikası' : 'Konya'"
                    />
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div style="width: 100px" class="text-right">Fiyat</div>
                    <div class="text-right">Miktar</div>
                  </div>
                </div>
              </template>

              <template #[`item.product_code`]="{ item }">
                <v-chip small label color="chip">
                  <span> {{ item.product_code }}</span>
                </v-chip>
              </template>

              <template #[`item.paymCond`]="{ item }">
                <v-select
                  v-if="userData.role === 'PLSYR'"
                  v-model="item.paymCond"
                  filled
                  rounded
                  class="rounded-lg rounded-kor"
                  hide-details
                  background-color="chip"
                  style="zoom: 0.85"
                  dense
                  attach
                  :items="kondisyonItems"
                  @change="condChange(item)"
                ></v-select>
              </template>

              <template #[`item.pictures`]="{ item }">
                <v-tooltip v-if="item.pictures" right color="white">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                      v-bind="attrs"
                      tile
                      class="rounded d-flex align-center justify-center"
                      :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                      :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                      :color="item.pictures ? 'white' : 'primary'"
                      v-on="on"
                    >
                      <v-img
                        v-if="item.pictures"
                        :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                        :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                        :src="$baseUrlAPI + '' + item.pictures"
                      ></v-img>
                      <v-icon v-else>
                        {{ icons.mdiCameraOff }}
                      </v-icon>
                    </v-card>
                  </template>
                  <v-img width="250" height="250" :src="$baseUrlAPI + '' + item.pictures"></v-img>
                </v-tooltip>
                <v-card
                  v-else
                  tile
                  class="rounded d-flex align-center justify-center"
                  :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :color="item.pictures ? 'white' : 'primary'"
                >
                  <v-img
                    v-if="item.pictures"
                    :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :src="$baseUrlAPI + '' + item.pictures"
                  ></v-img>
                  <v-icon v-else>
                    {{ icons.mdiCameraOff }}
                  </v-icon>
                </v-card>
              </template>

              <template #[`item.tekirdag_fabrikasi`]="{ item }">
                <div v-if="item.product_01" class="d-flex justify-space-between align-center">
                  <div class="text-caption font-weight-bold text-right" style="width: 100px">
                    <div v-if="$vuetify.breakpoint.xsOnly" class="secondary--text text-plain">Tekirdağ</div>
                    <div class="font-weight-bold" style="line-height: 1">
                      {{ item.product_price_01 | currency }}
                    </div>
                  </div>
                  <Counter
                    :uid="item.id"
                    :birim="item.birim"
                    :birim-ritim="50"
                    :miktar="item.product_pic_01"
                    :paym-cond="item.paymCond"
                    plant-id="01"
                    bg-color="transparent elevation-0"
                    button-color="white"
                    :dark="true"
                    :light="false"
                    :status="false"
                    :filled="false"
                    :solo="true"
                    @selected="selected"
                  >
                  </Counter>
                </div>
              </template>

              <template #[`item.konya_fabrikasi`]="{ item }">
                <div v-if="item.product_02" class="d-flex justify-space-between align-center">
                  <div class="text-caption font-weight-bold text-right" style="width: 100px">
                    <div v-if="$vuetify.breakpoint.xsOnly" class="warning--text text-plain">Konya</div>
                    <div class="font-weight-bold" style="line-height: 1">
                      {{ item.product_price_02 | currency }}
                    </div>
                  </div>
                  <Counter
                    :uid="item.id"
                    :birim="item.birim"
                    :birim-ritim="50"
                    :miktar="item.product_pic_02"
                    :paym-cond="item.paymCond"
                    plant-id="02"
                    bg-color="transparent elevation-0"
                    button-color="white"
                    :dark="true"
                    :light="false"
                    :status="false"
                    :filled="false"
                    :solo="true"
                    @selected="selected"
                  >
                  </Counter>
                </div>
              </template>
            </v-data-table>
          </v-sheet>
        </v-card>
      </div>
      <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <div v-if="maintenanceMode">
          <v-alert type="error" prominent outlined rounded="pill">
            Şu anda güncelleme yapıladığından dolayı satış yapılamaktadır.
          </v-alert>
        </div>
        <div v-else>
          <v-alert
            v-if="!$store.state.plasiyerCustomer"
            text
            prominent
            outlined
            class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0"
            :type="loadingKota ? 'info' : 'warning'"
          >
            <div v-if="loadingKota">Lütfen bekleyin kota bilgileri güncelleniyor</div>
            <div v-else>Lütfen yukarıdan bir müşteri seçin</div>
          </v-alert>
          <div v-else>
            <v-row>
              <v-col>
                <v-card
                  :loading="limitLoading || loadingKota || activiteLoading"
                  :disabled="limitLoading || loadingKota || activiteLoading"
                  :elevation="$vuetify.breakpoint.smAndDown ? '0' : '3'"
                  rounded="xl"
                  class="mx-auto mt-md-9"
                  :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
                  :max-width="$vuetify.breakpoint.smAndDown ? '92%' : 700"
                >
                  <v-img
                    class="white--text align-center"
                    :class="$vuetify.breakpoint.smAndDown ? 'rounded-xl' : 'rounded-xl'"
                    src="@/assets/images/pages/header-01.jpg"
                    gradient="to bottom, rgba(0,0,0,0), rgba(49,45,75,1)"
                  >
                    <div class="px-6">
                      <div class="pt-5 pb-3">
                        <div class="text-h6">İlave Bilgiler</div>
                        <div class="text-caption">Lütfen Aşağıdan Seçimlerinizi Yapınız</div>
                      </div>
                      <v-alert v-if="limitError" type="error" rounded="lg" prominent class="mb-5">
                        <h4>Hata oluştu lütfen tekrar deneyin</h4>
                        <small>Pasif müşteri seçtiniz lütfen aktif bir müşteri seçin</small>
                      </v-alert>
                      <v-alert
                        v-else
                        :type="limitLoading ? 'info' : openrisk < 0 ? 'error' : 'success'"
                        prominent
                        :text="openrisk > 0"
                        rounded="lg"
                        class="mb-5"
                      >
                        <h3 v-if="limitLoading" class="ml-3">Limit verileri bekleniyor</h3>
                        <div v-else class="ml-3 mt-2">
                          <h3 style="line-height: 0.75; color: white !important">{{ openrisk | currency }}</h3>
                          <small>Güncel Limit</small>
                        </div>
                      </v-alert>

                      <v-select v-model="tipDeger" label="Sipariş Tipi Seçiniz" :items="tipItems" @change="tipChange">
                      </v-select>

                      <v-select
                        v-if="tipDeger === 'O9' && baglantiTipItems.length > 0"
                        v-model="baglantiTipDeger"
                        :items="baglantiTipItems"
                        @change="baglantiTipChange"
                        label="Bağlantı Tipi Seçiniz"
                        return-object
                        item-text="stext"
                      >
                      </v-select>
                      <v-alert dense :type="hasBaglanti" text v-else-if="tipDeger === 'O9'">{{ baglantiText }}</v-alert>

                      <v-select
                        v-if="tipDeger != 'O9' && tipDeger != '' && activiteItems.length > 0"
                        v-model="activiteDeger"
                        label="Aktivite Seçiniz"
                        :items="activiteItems"
                        @change="activiteChange"
                      >
                      </v-select>

                      <v-select
                        v-if="tipDeger != 'O9' && tipDeger != ''"
                        v-model="kotaDeger"
                        label="Kota Seçiniz"
                        :items="kotaItems"
                        @change="kotaChange"
                      >
                        <template v-slot:item="data">
                          <div class="my-3">
                            <div>
                              {{ data.item.text }}
                            </div>
                            <div v-if="data.item.limit > 0" class="text-caption">
                              <div class="secondary--text mr-3">
                                Limit : {{ data.item.limit | currency({ symbol: 'KG', fractionCount: '0' }) }}
                              </div>
                              <div class="warning--text mr-3">
                                Kullanılan :
                                {{ data.item.sKullanilan | currency({ symbol: 'KG', fractionCount: '0' }) }}
                              </div>
                              <div class="error--text">
                                Kalan :
                                {{
                                  (data.item.limit - data.item.sKullanilan)
                                    | currency({ symbol: 'KG', fractionCount: '0' })
                                }}
                              </div>
                            </div>
                          </div>
                        </template>
                        <template v-slot:selection="data">
                          <div>
                            {{ data.item.text }}
                          </div>
                        </template>
                      </v-select>

                      <v-select
                        v-model="kondisyonDeger"
                        v-if="tipDeger != 'O9' && tipDeger != ''"
                        :disabled="!tipDeger"
                        label="Ödeme Tipi Seçiniz"
                        :items="kondisyonItems"
                        @change="kondisyonChange"
                      />
                      <v-btn
                        :disabled="limitError"
                        block
                        depressed
                        rounded
                        color="tertiary"
                        x-large
                        class="my-6"
                        @click="ilaveDevam"
                      >
                        Devam
                      </v-btn>
                    </div>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Counter from '@/components/Counter.vue'
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import {
  mdiArrowLeft,
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiFlash,
  mdiMagnify,
  mdiPlusThick,
  mdiSquareEditOutline,
} from '@mdi/js'
import { postData } from '@utils'
import { onMounted, ref, watch } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import orderStoreModule from '../orderStoreModule'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    Counter,
    VSnackbars,
    CustomerList,
  },
  setup() {
    const ORDER_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    // })
    const dataListTable = ref([])
    const dataItem = ref({})
    const snackbar = ref([])
    const search = ref('')
    const product_title = ref('')
    const product_code = ref('')
    const status = ref(false)
    const tableColumns = [
      {
        text: '',
        value: 'pictures',
        width: '40px',
        sortable: false,
      },
      {
        text: 'Kod',
        value: 'product_code',
        width: '100px',
        sortable: false,
        filter: value => {
          if (!product_code.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_code.value.toLocaleUpperCase('TR')) !== -1
        },
      },
      {
        text: 'Ürün Adı',
        value: 'product_title',
        sortable: false,
        class: 'min-width-400',
        filter: value => {
          if (!product_title.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_title.value.toLocaleUpperCase('TR')) !== -1
        },
      },

      {
        text: 'Ödeme Tipi',
        value: 'paymCond',
        width: '175px',
        sortable: false,
        align: userData.role === 'PLSYR' ? 'left' : 'left paymCond-hidden',
      },

      {
        text: 'Tekirdağ',
        value: 'tekirdag_fabrikasi',
        align: 'right',
        class: 'plant_tekirdag',
        width: '300px',
        sortable: false,
      },
      {
        text: 'Konya',
        value: 'konya_fabrikasi',
        class: 'plant_konya',
        width: '300px',
        align: 'right',
        sortable: false,
      },
    ]
    if (window.screen.availWidth <= 600) {
      tableColumns.forEach(item => {
        delete item.filter
      })
    }
    const statusCustomer = ref(false)
    const kotaItems = ref([])
    const kotaDeger = ref()
    const kondisyonItems = ref([])
    const kondisyonDeger = ref()
    const tipItems = ref([
      { text: 'Ön Ödemeli', value: 'O1P' },
      { text: 'Normal', value: 'O1' },
      { text: 'Bağlantılı', value: 'O9' },
    ])
    const tipDeger = ref('')
    const baglantiTipDeger = ref({})
    const baglantiTipItems = ref([])
    const delivery_date = store.state['app-order'].deliveryDate

    const loadingKota = ref(true)
    const loadingConditions = ref(true)
    const plasyirFn = () => {
      loadingKota.value = true
      loadingConditions.value = true
      postData({
        method: 'getKotaList',
        delivery_date,
      }).then(response => {
        kotaItems.value = []
        if (response.error === 0) {
          kotaItems.value = response.response.map(({ limit, sKullanilan, limitNum, notes }) => ({
            text: notes,
            value: limitNum,
            limit,
            sKullanilan,
          }))
        }
        kotaItems.value.unshift({
          text: 'Kota Kullanmadan Devam Et',
          value: '0',
        })
        loadingKota.value = false
      })

      postData({
        method: 'getMemberPaymentConditions',
        customer: store.state.plasiyerCustomer,
      }).then(response => {
        if (response.error) {
          return
        }
        kondisyonItems.value = response.map(({ paymcondtxt, paymcond }) => ({
          text: paymcondtxt,
          value: paymcond,
        }))
      })
      loadingConditions.value = false
    }

    const maintenanceMode = ref(false)

    onMounted(() => {
      postData({
        method: 'checkMaintenance',
      }).then(response => {
        if (response.error) {
          return
        }

        maintenanceMode.value = response.maintenance_mode
      })
      plasyirFn()
      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else if (userData.role === 'CUST') {
        status.value = true
        loading.value = true
        statusCustomer.value = true
        fetchBasket()
      }
      if (store.state.plasiyerCustomer) {
        limitCheck()
      }
    })
    const condChange = val => {
      const paymcond = val.paymCond == '100' ? val.paymCond : `0${val.paymCond}`
      dataListTable.value.find(item => {
        if (item.id === val.id) {
          item.priceAll.forEach(el => {
            if (el.paymcond == paymcond) {
              if (el.plant == '01') {
                item.product_price_01 = el.price1
              } else if (el.plant == '02') {
                item.product_price_02 = el.price1
              }
            }
          })
        }
      })
    }

    const trashBasket = () => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Kota değişikliğinden sonra sepetiniz temizlenir. Devam etmek istiyor musunuz?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: 'Sepet Temizlendi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
              postData({
                method: 'setMemberKota',
                limitnum: kotaDeger.value,
                delivery_date,
              }).then(response => {
                const validFromRaw = response.detail.validFrom.slice(0, 10)
                const validFromSplit = validFromRaw.split('.')
                const validFrom = `${validFromSplit[2]}-${validFromSplit[1]}-${validFromSplit[0]}`

                const validUntilRaw = response.detail.validUntil.slice(0, 10)
                const validUntilSplit = validUntilRaw.split('.')
                const validUntil = `${validUntilSplit[2]}-${validUntilSplit[1]}-${validUntilSplit[0]}`

                store.commit('app-order/DELIVERY_DATE_MAX', validUntil)
                store.commit('app-order/DELIVERY_DATE_MIN', validFrom)
              })
            })
          })
        } else {
          kotaDeger.value = ''
        }
      })
    }
    const trashBasketBaglanti = () => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Sepetinizde sadece bağlantılı ürünler olması gerektiği için sepetiniz temizlenecek. Devam etmek istiyor musunuz?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('app-order/fetchTrashBasket').then(() => {
            store.dispatch('app-order/fetchActiveBasketList').then(response => {
              store.commit('app/TOGGLE_BASKET_DRAWER', false)
              Vue.swal({
                title: 'Sepet Temizlendi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
              postData({
                method: 'setLinkedOrder',
                doctype: baglantiTipDeger.value.doctype,
                docnum: baglantiTipDeger.value.docnum,
              }).then(response => {
                kondisyonDeger.value = response.detail.PAYMCOND
              })
            })
          })
        } else {
          baglantiTipDeger.value = ''
        }
      })
    }
    const kotaChange = () => {
      if (store.state['app-order'].basketItemCount > 0) {
        trashBasket()
      } else {
        postData({
          method: 'setMemberKota',
          limitnum: kotaDeger.value,
          delivery_date,
        }).then(response => {
          const validFromRaw = response.detail.validFrom.slice(0, 10)
          const validFromSplit = validFromRaw.split('.')
          const validFrom = `${validFromSplit[2]}-${validFromSplit[1]}-${validFromSplit[0]}`

          const validUntilRaw = response.detail.validUntil.slice(0, 10)
          const validUntilSplit = validUntilRaw.split('.')
          const validUntil = `${validUntilSplit[2]}-${validUntilSplit[1]}-${validUntilSplit[0]}`

          store.commit('app-order/DELIVERY_DATE_MAX', validUntil)
          store.commit('app-order/DELIVERY_DATE_MIN', validFrom)
        })
      }
    }

    const kondisyonChange = () => {
      postData({
        method: 'setMemberCondition',
        condition: kondisyonDeger.value,
      }).then(response => {})
    }
    const hasBaglanti = ref('info')
    const baglantiText = ref('Kontrol Ediliyor')
    const tipChange = () => {
      hasBaglanti.value = 'info'
      baglantiText.value = 'Kontrol Ediliyor'

      postData({
        method: 'changeOrderType',
        orderType: tipDeger.value,
      }).then(response => {
        // kondisyonItems
        baglantiTipItems.value = []
        if (response.text === 'Bağlantılı Anlaşmalar') {
          baglantiTipItems.value = response.detail
        } else {
          hasBaglanti.value = 'warning'
          baglantiText.value = 'Listelenecek anlaşma bulunamadı'
        }
        store.commit('PAYMENT_TYPE', tipDeger.value)
        kondisyonItems.value = []
        kondisyonDeger.value = null
        for (const [key, value] of Object.entries(response.detail)) {
          kondisyonItems.value.push({
            text: value,
            value: key,
          })
        }
      })
    }

    const baglantiTipChange = () => {
      if (store.state['app-order'].basketItemCount > 0) {
        trashBasketBaglanti()
      } else {
        postData({
          method: 'setLinkedOrder',
          doctype: baglantiTipDeger.value.doctype,
          docnum: baglantiTipDeger.value.docnum,
        }).then(response => {
          kondisyonDeger.value = response.detail.PAYMCOND
        })
      }
    }
    const refreshCustomer = () => {
      loading.value = true
      kondisyonItems.value = []
      kotaItems.value = []
      kondisyonDeger.value = ''
      kotaDeger.value = ''
      tipDeger.value = ''
      statusCustomer.value = false
      plasyirFn()
      fetchBasket()
    }
    const limitLoading = ref(true)
    const limitError = ref(false)
    const openrisk = ref(0)

    const limitCheck = () => {
      limitLoading.value = true
      limitError.value = false
      postData({
        method: 'getCurrentRiskAndLimit',
      })
        .then(response => {
          if (response.error) {
            limitError.value = true
          } else {
            openrisk.value = response.detail.openrisk
          }
          limitLoading.value = false
        })
        .catch(err => {
          limitError.value = true
          loadingConditions.value = false
          loadingKota.value = false
          limitLoading.value = false
        })
    }

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        loading.value = true
        kondisyonItems.value = []
        kotaItems.value = []
        activiteDeger.value = []
        activiteItems.value = ''
        kondisyonDeger.value = ''
        kotaDeger.value = ''
        tipDeger.value = ''
        statusCustomer.value = false
        plasyirFn()
        fetchBasket()
        limitCheck()
        activiteChange()
      },
    )
    const ilaveDevam = () => {
      if (kotaDeger.value && kondisyonDeger.value && tipDeger.value) {
        status.value = true
        statusCustomer.value = true
        fetchBasket()
        fetchDatas()
      }
      if (tipDeger.value == 'O9' && baglantiTipDeger.value.doctype) {
        status.value = true
        statusCustomer.value = true
        fetchBasket()
        fetchDatas()
      }
    }
    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const selectedRows = ref([])
    const count = ref(1)
    const selected = item => {
      // fetchBasket()
      // const snackbarData = dataListTable.value.find(i => i.id === item.uid)
      // if (item.number != 0) {
      //   // count.value = restaurant.value
      //   snackbar.value.push({
      //     color: null,
      //     message: {
      //       status: true,
      //       plant: item.plantId === '01' ? 'Tekirdağ' : 'Konya',
      //       color: item.plantId === '01' ? 'secondary' : 'warning',
      //       product: snackbarData.product_title,
      //       count: item.number,
      //       birim: 'Ad',
      //     },
      //   })
      // } else {
      //   snackbar.value.push({
      //     color: 'error',
      //     message: {
      //       status: false,
      //       plant: item.plantId === '01' ? 'Tekirdağ' : 'Konya',
      //       color: item.plantId === '01' ? 'secondary' : 'warning',
      //       product: snackbarData.product_title,
      //       count: item.number,
      //       birim: 'Ad',
      //     },
      //   })
      // }
    }
    const itemRowBackground = item => (item.selected ? 'style-1' : 'style-2')

    watch(
      () => store.getters['app-order/doneBasket'],
      () => {
        fetchDatas(store.getters['app-order/doneBasket'])

        // fetchBasket()
        // refreshBasket()
      },
    )

    const fetchBasket = async () => {
      await store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {
          fetchDatas(response.detail)
        } else {
          // console.table('hatalı =>', response)
        }
      })
    }
    const fetchDatas = async basketList => {
      const id = 0
      const page = 1
      const count = 6
      const plant = '01'
      const method = 'getProductsByCtgId'
      const delivery_date = store.state['app-order'].deliveryDate

      await store
        .dispatch('postMethod', {
          method,
          id,
          page,
          count,
          plant,
          delivery_date,
        })
        .then(response => {
          const paymcond = kondisyonDeger.value == '100' ? kondisyonDeger.value : `0${kondisyonDeger.value}`
          const productList = response.detail.products.map(item => {
            const items = {}
            items.id = item.id
            items.product_code = item.urun_kodu
            items.product_title = item.baslik
            items.paymCond = kondisyonDeger.value
            items.pictures = item.resim
            items.product_01 = true
            items.product_02 = true
            items.priceAll = item.priceAll
            if (item.priceAll.length > 0) {
              if (userData.role === 'PLSYR' && kondisyonDeger.value != '003') {
                item.priceAll.forEach(el => {
                 
                  if (el.paymcond == paymcond) {
                    if (el.plant == '01') {
                      items.product_price_01 = el.price1
                    } else if (el.plant == '02') {
                      items.product_price_02 = el.price1
                    }
                  }
                })
              } else {
                const price_01 = item.priceAll.find(i => i.plant === '01')
                const price_02 = item.priceAll.find(i => i.plant === '02')
                if (price_01) {
                  items.product_price_01 = price_01?.price1
                }
                if (price_02) {
                  items.product_price_02 = price_02?.price1
                }
              }
            }
            if (typeof items.product_price_01 === 'undefined') {
              items.product_01 = false
            }
            if (typeof items.product_price_02 === 'undefined') {
              items.product_02 = false
            }

            // if (item.priceAll.length > 0) {
            //   const price_01 = item.priceAll.find(i => i.paymcond === '01')
            //   const price_02 = item.priceAll.find(i => i.paymcond === '02')
            //   if (price_01) {
            //     items.product_price_01 = price_01.price1
            //   } else {
            //     items.product_01 = false
            //   }
            //   if (price_02) {
            //     items.product_price_02 = price_02.price1
            //   } else {
            //     items.product_02 = false
            //   }
            // } else {
            //   items.product_01 = false
            //   items.product_02 = false
            // }

            return items
          })

          if (basketList) {
            basketList.forEach(i => {
              productList.find(findItem => {
                if (findItem.id == i.uid) {
                  findItem.selected = true
                  findItem.paymCond = i.paymCond
                  if (i.selected_plant === '01') {
                    findItem.product_pic_01 = Number(i.qty)
                  } else if (i.selected_plant === '02') {
                    findItem.product_pic_02 = Number(i.qty)
                  }
                }
              })
            })
          }
          dataListTable.value = productList
          totalDataListTable.value = Number(response.detail.toplam)
          loading.value = false
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]

    const activiteLoading = ref(true)

    const activiteDeger = ref(null)
    const activiteItems = ref([])
    const activiteChange = () => {
      if (activiteItems.value.length === 0) {
        activiteLoading.value = true
        postData({
          method: 'getAktiviteList',
          delivery_date: store.state['app-order'].deliveryDate,
        }).then(response => {
          if (typeof response.response !== 'undefined') {
            activiteItems.value = response.response.map(item => {
              return {
                value: item.ANLASMAID,
                text: item.STEXT,
              }
            })
          }
          activiteLoading.value = false
        })
      } else {
        postData({
          method: 'setMemberAktivite',
          anlasmaid: activiteDeger.value,
          delivery_date: store.state['app-order'].deliveryDate,
        }).then(response => {})
      }
    }

    onMounted(() => {
      activiteChange()
    })
    return {
      baglantiText,
      hasBaglanti,
      activiteLoading,
      activiteDeger,
      activiteItems,
      activiteChange,
      maintenanceMode,
      loadingConditions,
      loadingKota,
      limitLoading,
      limitError,
      openrisk,

      statusFind,
      userData,
      dataItem,
      dataListTable,
      tableColumns,
      totalDataListTable,
      loading,
      itemRowBackground,
      selected,
      options,
      dataTotalLocal,
      selectedRows,
      fetchDatas,
      count,
      snackbar,
      search,
      product_title,
      product_code,
      status,
      statusCustomer,
      kotaDeger,
      kotaChange,
      kotaItems,
      kondisyonItems,
      kondisyonDeger,
      kondisyonChange,
      baglantiTipItems,
      baglantiTipDeger,
      baglantiTipChange,
      tipItems,
      tipDeger,
      tipChange,
      ilaveDevam,
      refreshCustomer,
      condChange,

      // icons
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiFlash,
        mdiEye,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiMagnify,
        mdiArrowLeft,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
        footer: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    addBasket(plant, product, id, count, birim) {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid: id,
          qty: count,
          plant,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',

              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.snackbar.push({ product, count, birim })
          }
        })
    },
    async addToBasket(plants, product, id, count, birim) {
      if (plants.length === 1) {
        this.addBasket(plants[0].plant, product, id, count, birim)

        return
      }

      const plantsMap = new Map(plants.map(object => [object.plant, object.stext]))

      const { value: plant } = await Vue.swal({
        icon: 'info',
        title: 'Fabrika Seçimi',
        text: 'Lütfen ürünü eklemek istediğiniz fabrikayı seçiniz',
        input: 'select',
        inputOptions: plantsMap,
      })

      if (plant) {
        this.addBasket(plant, product, id, count, birim)
      }
    },
    onResize() {
      const val = this.$vuetify.breakpoint.xsOnly ? 120 : 90
      this.dataTableHeight = window.innerHeight - val - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '~@core/layouts/styles/_variables';

.search-mobil {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 70px;
}

.search-mobil-plasiyer {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 135px !important;
}

.text-plain {
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
}

.po-0 {
  .v-toolbar__content {
    padding: 0px 18px 0px 15px !important;
  }
}

// .v-data-table__wrapper::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #3b355a;
// }
// .v-data-table__wrapper::-webkit-scrollbar-thumb {
//   background-color: #0083e8;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_konya {
//   border-bottom: 1px solid #ffb400 !important ;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_tekirdag {
//   border-bottom: 1px solid #0083e8 !important ;
// }

.min-width-400 {
  min-width: 360px;
}

.style-1 {
  background-color: rgba(255, 255, 255, 0.05);
}

.style-2 {
  // background-color: rgb(114, 114, 67);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: auto !important;
  }

  .theme--light tr {
    background-color: #fff !important;
  }

  .theme--dark tr {
    background-color: #3b355a !important;
  }

  .v-data-table__empty-wrapper {
    td {
      position: relative !important;
    }
  }

  .v-data-table__wrapper {
    tr {
      position: relative;
      padding: 15px 0;

      border-bottom: thin solid rgba(231, 227, 252, 0.14);

      td:nth-child(1) {
        position: absolute;

        .v-data-table__mobile-row__header {
          display: none;
        }
      }

      td:nth-child(2),
      td:nth-child(3) {
        padding-top: 5px !important;

        .v-data-table__mobile-row__header {
          display: none;
        }

        .v-data-table__mobile-row__cell {
          padding-left: 70px;
          text-align: left !important;
        }
      }

      td:nth-child(4) {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px !important;

        .v-data-table__mobile-row__header {
          display: none;
        }
      }

      td:nth-child(5) {
        display: flex;
        justify-content: flex-end;

        .v-data-table__mobile-row__header {
          display: none;
        }
      }

      td:nth-child(6) {
        display: flex;
        justify-content: flex-end;

        .v-data-table__mobile-row__header {
          display: none;
        }
      }
    }
  }

  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child,
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
    border: none !important;
  }
}

.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 30%) !important;
}

.paymCond-hidden {
  display: none !important;
}

.rounded-kor {
  .v-input__slot {
    padding: 0 !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
}
</style>
